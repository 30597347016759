<template>
  <div>
    <h6>Action taking user tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#usershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#useremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#useraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#usercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientname#]</Chip>
    </span>
    <Divider />
    <h6>Admin employee tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminusershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminuserfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminuseremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminuserprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminuserpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminuseraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminusercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#adminuserphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#loginusername#]</Chip>
    </span>
    <Divider />
    <h6>Voter tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#votershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voterfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voteremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voterprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voterpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voteraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#votercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voterphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientname#]</Chip>
    </span>
    <Divider />
    <h6>Support user tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportusershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportuserfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportuseremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportuserprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportuserpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportuseraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportusercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#supportuserphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientname#]</Chip>

    </span>
    <Divider />
    <h6>Current user tags :</h6>
    <span>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientloginurl#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#accesscode#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#mobileapplink#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#usershortname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userfullname#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#useremailid#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userprimarymobilenumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userpassword#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#useraadharnumber#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#usercurrentaddress#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#userphotofilename#]</Chip>
      <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#clientname#]</Chip>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>